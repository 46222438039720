import { arrayConfigToOptions } from 'lib/enum'


export const AdditionalContactDetailsOption = [
  { name: 'Phone number', value: 0 },
  { name: 'Email Address', value: 1 },
  { name: 'House Address', value: 2 },
]


export const amountType = [
  { value: 1, name: 'Caution deposit' },
  { value: 2, name: 'Sale' },
  { value: 3, name: 'Aggregator Lease' },
  { value: 4, name: 'Aggregator Sale' },
  { value: 6, name: 'Miscellaneous' },
  { value: 9, name: 'PartnerLease' },
  { value: 10, name: 'PartnerSale' },
  { value: 11, name: 'OutrightSaleBalance' },
  { value: 12, name: 'MPOSLease' },
  { value: 13, name: 'MPOSSale' },
  { value: 14, name: 'MPOSPartnerLease' },
  { value: 15, name: 'MPOSPartnerSale' },
]

export const swipeAgentType = [3, 7, 10, 13, 16]

export const DocumentStatus = {
  0: 'Pending',
  1: 'Approved',
  2: 'Rejected',
  3: 'Invalid',
}
export const getBackgroundColor = (status) => {
  switch (status) {
    case 0:
      return '#F3A300'
    case 1:
      return '#52BE95'
    case 2:
      return '#D8314F'
    default:
      return '#d3d3d3'
  }
}